import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AdminBlogs } from '../pages/administration/AdminBlogs';
import { AddBlog } from '../pages/administration/AddBlog';
import { AdminEnquiry } from '../pages/administration/AdminEnquiry';
import { AdminSingleUser } from '../pages/administration/AdminSingleUser';
import { AdminUsers } from '../pages/administration/AdminUsers';
import NotFound from '../pages/layout/NotFound';
import { EditBlog } from '../pages/administration/EditBlog';
import { AdminComments } from '../pages/administration/AdminComments';

export const AdminRoutes = () => {
	return (
		<Switch>
			<Redirect exact from='/administration' to='/administration/enquiry' />
			<Route exact path='/administration/enquiry' component={AdminEnquiry} />
			<Route exact path='/administration/blogs' component={AdminBlogs} />
			<Route exact path='/administration/users' component={AdminUsers} />
			<Route exact path='/administration/addblog' component={AddBlog} />
			<Route exact path='/administration/blogs/:id' component={EditBlog} />
			<Route exact path='/administration/comments' component={AdminComments} />
			<Route
				exact
				path='/administration/users/:student_id'
				component={AdminSingleUser}
			/>
			<Route component={NotFound} />
		</Switch>
	);
};
