import { Box, Center, Flex, Image, Text } from '@chakra-ui/react';
import {
	faBlog,
	faClipboardList,
	faComments,
	faFileAlt,
	faPlus,
	faQuestionCircle,
	faThLarge,
	faUsers,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import { steps } from '../../../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Sidebar() {
	const { user } = useSelector((state) => state.auth);
	return (
		<Box
			height='100vh'
			minWidth='300px'
			display={{ base: 'none', lg: 'block' }}
			position='sticky'
			top='0'
			backgroundColor='gray.700'
			color='white'
		>
			<Center
				px='6'
				height='70px'
				mb='12'
				bg='white'
				borderRight='1px solid'
				borderColor='gray.100'
			>
				<Link to='/'>
					<Image
						width='160px'
						src={`${process.env.PUBLIC_URL}/media/logo.png`}
						alt='kmmias'
					/>
				</Link>
			</Center>
			{user && (
				<Flex direction='column' textAlign='left' p='2'>
					<Box
						as={NavLink}
						to='/'
						py='2'
						px='10'
						mb='1'
						_hover={{ backgroundColor: 'gray.600' }}
						exact
						rounded='md'
					>
						<FontAwesomeIcon icon={faThLarge} />
						<Text as='span' ml='2'>
							Dashboard
						</Text>
					</Box>
					{user.role === 'student' && (
						<Box
							as={NavLink}
							to={
								user.is_form_submitted
									? `/admission-form/form-submitted`
									: `/admission-form/${user.applying_for}/${
											steps[user.applying_for][user.current_form]
									  }`
							}
							mb='1'
							py='2'
							px='10'
							_hover={{ backgroundColor: 'gray.700' }}
							rounded='md'
						>
							<FontAwesomeIcon icon={faClipboardList} />
							<Text as='span' ml='2'>
								Admission Form
							</Text>
						</Box>
					)}
					{user.role === 'admin' && (
						<>
							<Box
								as={NavLink}
								to={'/administration/enquiry'}
								mb='1'
								py='2'
								px='10'
								_hover={{ backgroundColor: 'gray.700' }}
								rounded='md'
							>
								<FontAwesomeIcon icon={faQuestionCircle} />
								<Text as='span' ml='2'>
									Enquiries
								</Text>
							</Box>
							<Box
								as={NavLink}
								to={'/administration/users'}
								mb='1'
								py='2'
								px='10'
								_hover={{ backgroundColor: 'gray.700' }}
								rounded='md'
							>
								<FontAwesomeIcon icon={faUsers} />
								<Text as='span' ml='2'>
									Users
								</Text>
							</Box>
							<Box
								as={NavLink}
								to={'/administration/addblog'}
								mb='1'
								py='2'
								px='10'
								_hover={{ backgroundColor: 'gray.700' }}
								rounded='md'
							>
								<FontAwesomeIcon icon={faPlus} />
								<Text as='span' ml='2'>
									Add Blogs
								</Text>
							</Box>
							<Box
								as={NavLink}
								to={'/administration/blogs'}
								mb='1'
								py='2'
								px='10'
								_hover={{ backgroundColor: 'gray.700' }}
								rounded='md'
							>
								<FontAwesomeIcon icon={faBlog} />
								<Text as='span' ml='2'>
									Blogs
								</Text>
							</Box>
							<Box
								as={NavLink}
								to={'/administration/comments'}
								mb='1'
								py='2'
								px='10'
								_hover={{ backgroundColor: 'gray.700' }}
								rounded='md'
							>
								<FontAwesomeIcon icon={faComments} />
								<Text as='span' ml='2'>
									Comments
								</Text>
							</Box>
						</>
					)}
				</Flex>
			)}
		</Box>
	);
}
